import AddIcon from "@mui/icons-material/Add"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"
import React from "react"
import { ResourceTile } from "../../../molecules/ResourceTile"
import { Category, CategoryState, Resource } from "./ResourcesPage"
import SearchIcon from "@mui/icons-material/Search"

import * as S from "./style"
import { ChipBar } from "../../../molecules/ChipBar"

interface ResourcesPageContentProps {
  resourceList: Resource[]
  categoryList: CategoryState[]
  onCategorySelectChange: (id: number, selected: boolean) => void
  searchQuery: string
  onSearch: (query: string) => void
}

const ResourcesPageContent: React.FC<ResourcesPageContentProps> = ({
  resourceList,
  categoryList,
  onCategorySelectChange,
  searchQuery,
  onSearch,
}) => {
  const intl = useIntl()

  return (
    <S.Main>
      <S.Wrapper>
        <S.SearchBar>
          <S.SearchInput
            placeholder="Search"
            inputProps={{ "aria-label": "search resources" }}
            value={searchQuery}
            onChange={event => onSearch(event.target.value)}
            endAdornment={<SearchIcon />}
          />
        </S.SearchBar>
        {/* Temporairy disable - to do show chip bar on click filter icon (filter icon in search bar) */}
        {/* <S.ChipBar>
          <ChipBar
            items={categoryList.map(({ id, name, selected }) => ({
              label: name,
              value: id,
              selected,
            }))}
            addLabel={intl.formatMessage({
              defaultMessage: "Add category",
              description: "button label",
            })}
            onChange={onCategorySelectChange}
          />
        </S.ChipBar> */}
        <S.AddButton link={`/add-resource`} endIcon={<AddIcon />}>
          <FormattedMessage
            defaultMessage="Add custom resource"
            description="button label"
          />
        </S.AddButton>
        <S.List>
          {resourceList?.map(({ id, name, courseorganizationSet, kind }) => (
            <ResourceTile
              key={id}
              name={name}
              kind={kind.name}
              link={`/resources/${id}`}
              organizations={courseorganizationSet?.map(
                ({ organization }) => organization
              )}
            />
          ))}
        </S.List>
      </S.Wrapper>
    </S.Main>
  )
}

export { ResourcesPageContent }
