import { useQuery } from "@apollo/client"
import { RouteComponentProps, useLocation } from "@reach/router"
import queryString from "query-string"
import debounce from "lodash/debounce"
import { FormattedMessage, navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"

import * as S from "./style"
import { AuthContext } from "../../../../containers/AuthContext"
import { useIntlPathName } from "../../../../hooks/useIntlPathName"
import { HomeLayout, Tab } from "../HomeLayout"
import { userQuery } from "../../auth/LoginPage/queries"
import { categoryListQuery, resourceListQuery } from "../queries"
import { ResourcesPageContent } from "./ResourcesPageContent"
import { useResourceListSearch } from "../../../../hooks/search/useResourceListSearch"
import {
  changeSelectedCategories,
  getSelectedCategoriesFromSearchQuery,
} from "./utils"
import { SEO } from "../../../atoms/SEO"

export interface Category {
  id: number
  name: string
}

export interface CategoryListData {
  categoryList: Category[]
}

export interface CategoryState extends Category {
  selected: boolean
}

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

interface Kind {
  name: string
}

export interface Resource {
  id: number
  name: string
  slug: string
  description: string
  websiteUrl: string
  educationMaterials: EducationMaterial[]
  courseorganizationSet: OrganizationSet[]
  kind: Kind
}

export interface ResourceListData {
  courseList: Resource[]
}

const ResourcesPage: React.FC<RouteComponentProps> = () => {
  const intl = useIntl()
  const location = useLocation()
  const pathname = useIntlPathName()

  const query = queryString.parse(location.search).query
  const category = queryString.parse(location.search).category
  const searchQuery =
    typeof query === "undefined"
      ? undefined
      : typeof query === "string"
      ? query
      : query[0]
  const searchCategory =
    typeof category === "undefined"
      ? []
      : typeof category === "string"
      ? [category]
      : category

  const [firstLoad, setFirstLoad] = useState(true)

  const { loading: authLoading, authenticated } = useContext(AuthContext)
  const user = useQuery(userQuery, {
    fetchPolicy: "cache-first",
    skip: !authenticated,
  })
  const categoryList = useQuery<CategoryListData>(categoryListQuery, {
    fetchPolicy: "cache-and-network",
  })
  const categoryListData = categoryList?.data?.categoryList
  const selectedCategories = getSelectedCategoriesFromSearchQuery(
    categoryListData,
    searchCategory
  )

  const resourceList = useResourceListSearch(
    searchQuery,
    selectedCategories.filter(({ selected }) => !!selected).map(({ id }) => id)
  )

  const handleTabChange = (tab: Tab) => {
    navigate(tab.path)
  }

  const handleSearch = (query: string) => {
    const parsedQueryString = queryString.parse(location.search)
    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        ...parsedQueryString,
        query: query ? query : undefined,
      },
    })
    resourceList.search(query)
    navigate(url)
  }

  useEffect(() => {
    if (!resourceList.result.loading) {
      setFirstLoad(false)
    }
  }, [resourceList.result.loading])

  const resourceListData = resourceList.result?.data?.courseList

  const handleCategorySelectChange = (id: number, selected: boolean) => {
    const newSelectedCategories = changeSelectedCategories(
      selectedCategories,
      id,
      selected
    )
    const parsedQueryString = queryString.parse(location.search)
    const url = queryString.stringifyUrl({
      url: pathname,
      query: {
        ...parsedQueryString,
        category: newSelectedCategories
          .filter(({ selected }) => !!selected)
          .map(({ id }) => id),
      },
    })
    navigate(url)
  }

  return (
    <>
      <SEO
        subtitle={intl.formatMessage({
          defaultMessage: "Resources",
          description: "website subtitle",
        })}
      />
      <HomeLayout activeTab="resources" onTabChange={handleTabChange}>
        {authLoading || firstLoad ? (
          <S.Loading>
            <CircularProgress color="primary" />
          </S.Loading>
        ) : (
          <ResourcesPageContent
            searchQuery={searchQuery}
            onSearch={handleSearch}
            categoryList={selectedCategories}
            onCategorySelectChange={handleCategorySelectChange}
            resourceList={resourceListData}
          />
        )}
      </HomeLayout>
    </>
  )
}
export { ResourcesPage }
