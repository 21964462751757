import { useMutation, useQuery } from "@apollo/client"
import { RouteComponentProps, useParams } from "@reach/router"
import { navigate, useIntl } from "gatsby-plugin-react-intl"
import React, { useContext, useEffect } from "react"
import { AuthContext } from "../../../../containers/AuthContext"
import { SEO } from "../../../atoms/SEO"
import { Course, CourseLayout } from "../CourseLayout"
import { addUserCourseRegistrationMutation } from "../mutations"
import { courseQuery, userCourseQuery } from "../queries"

interface CourseQueryResult {
  course: Course
}

interface CoursePageProps {
  id: string
}

const CoursePage: React.FC<RouteComponentProps> = () => {
  const params = useParams()
  const intl = useIntl()
  const { loading: authLoading, authenticated } = useContext(AuthContext)

  const { data: course, loading: courseLoading } = useQuery<CourseQueryResult>(
    courseQuery,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        courseId: params.id,
      },
    }
  )

  const userCourse = useQuery(userCourseQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      courseId: params.id,
    },
    skip: !authenticated,
  })

  const [addUserCourseRegistration, addUserCourseRegistrationOpts] =
    useMutation(addUserCourseRegistrationMutation)

  const handleRegisterClick = async () => {
    if (authenticated) {
      await addUserCourseRegistration({
        variables: {
          courseId: params.id,
        },
      })
      await userCourse.refetch()
    } else {
      navigate("/auth/login")
    }
  }

  const handleGoToLessonplanClick = () => {
    navigate("/")
  }

  return (
    <>
      <SEO
        subtitle={`${intl.formatMessage({
          defaultMessage: "Resources",
          description: "website subtitle",
        })} - ${course?.course?.name ? course?.course?.name : ""}`}
      />
      <CourseLayout
        course={course?.course}
        userAuthenticated={authenticated}
        userRegistration={userCourse.data?.userCourseRegistration}
        loading={authLoading || courseLoading || userCourse.loading}
        onRegisterClick={handleRegisterClick}
        onGoToLessonplanClick={handleGoToLessonplanClick}
      />
    </>
  )
}
export { CoursePage }
