import { useCallback, useState } from "react"
import debounce from "lodash/debounce"
import { useQuery } from "@apollo/client"
import { resourceListQuery } from "../../components/sets/home/queries"
import { ResourceListData } from "../../components/sets/home/ResourcesPage/ResourcesPage"

export const useResourceListSearch = (
  query?: string,
  categoryIds?: number[]
) => {
  const [searchQuery, setSearchQuery] = useState<string>(query)

  const resourceList = useQuery<ResourceListData>(resourceListQuery, {
    fetchPolicy: "cache-and-network",
    variables: {
      query: searchQuery,
      categoryIds: categoryIds?.length > 0 ? categoryIds : undefined,
    },
  })

  const search = useCallback(debounce(setSearchQuery, 500), [query])

  const handleSetInputText = query => {
    search(query)
  }

  return {
    result: resourceList,
    search: handleSetInputText,
  }
}
