import { useLocation } from "@reach/router"
import { useIntl } from "gatsby-plugin-react-intl"

export const useIntlPathPrefix = () => {
  const intl = useIntl()
  const location = useLocation()

  const locale = intl.locale
  const pathname = location.pathname

  return pathname.startsWith(`/${locale}/`) || pathname === `/${locale}`
    ? `/${locale}`
    : ""
}
