import { Button } from "@mui/material"
import { Link } from "gatsby-plugin-react-intl"
import React from "react"
import styled from "styled-components"

export const StyledButton = styled(props => <Button {...props} />)``

export const StyledAnchor = styled(props => <a {...props} />)`
  text-decoration: inherit;
  color: inherit;
  text-decoration: none;
`

export const StyledLink = styled(props => <Link {...props} />)`
  text-decoration: inherit;
  color: inherit;
  text-decoration: none;
`
