import React, { ReactNode } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import * as S from "./styles"
import { Link } from "gatsby-plugin-react-intl"
import { ButtonProps } from "@mui/material"

export type ButtonLinkType = "internal" | "external"

type AnchorProps = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>

interface LinkComponentProps {
  link: string
  linkType: ButtonLinkType
  anchorProps: AnchorProps
}

interface ButtonLinkProps extends ButtonProps {
  link: string
  linkType?: ButtonLinkType
  anchorProps?: AnchorProps
}

const LinkComponent: React.FC<LinkComponentProps> = ({
  link,
  linkType,
  anchorProps,
  children,
}) => {
  if (linkType === "internal") {
    return <S.StyledLink to={link}>{children}</S.StyledLink>
  }
  return (
    <S.StyledAnchor href={link} {...anchorProps}>
      {children}
    </S.StyledAnchor>
  )
}

const ButtonLink: React.FC<ButtonLinkProps> = ({
  link,
  linkType = "internal",
  anchorProps = {},
  children,
  ...props
}) => {
  return (
    <LinkComponent linkType={linkType} link={link} anchorProps={anchorProps}>
      <S.StyledButton {...props}>{children}</S.StyledButton>
    </LinkComponent>
  )
}

export { ButtonLink }
