import MenuIcon from "@mui/icons-material/Menu"
import { Button, CardContent, InputBase } from "@mui/material"
import React from "react"
import styled from "styled-components"
import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import { ButtonLink } from "../../../atoms/ButtonLink"

export const Main = styled.main`
  padding: 24px;
  width: 100%;
`

export const Wrapper = styled.div`
  margin: 120px 0 0 0;

  @media screen and (min-width: 800px) {
    margin: 120px 10% 0 10%;
  }
  @media screen and (min-width: 1200px) {
    margin: 130px 20% 0 20%;
  }
`

export const Loading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40vh 0;
`

export const SearchBar = styled.div`
  background: rgb(0, 0, 0, 5%);
  margin: 0 0 20px 0;
  border-radius: 5px;
`
export const SearchInput = styled(props => <InputBase {...props} />)`
  width: 100%;
  padding: 8px 20px;
  svg {
    path {
      fill: rgb(0, 0, 0, 50%);
    }
  }
`
export const ChipBar = styled.div`
  margin: 0 0 10px 0;
`
export const AddButton = styled(props => (
  <ButtonLink variant="contained" size="large" fullWidth {...props} />
))`
  color: ${props => props.theme.palette.text.primary};
  background: #fff;
  margin: 0 0 40px 0;
  justify-content: space-between;
  box-shadow: ${props => props.theme.shadows[1]};
  text-transform: none;
  font-weight: normal;
  &:hover {
    box-shadow: ${props => props.theme.shadows[1]};
    background: rgb(0, 0, 0, 4%);
  }
`

export const MenuButton = styled(props => <Button variant="text" {...props} />)`
  @media screen and (min-width: 600px) {
    display: none;
  }
`

export const MenuButtonIcon = styled(props => <MenuIcon {...props} />)`
  margin-right: 8px;
`

export const Header = styled.h2`
  text-align: center;
  margin-bottom: 40px;
`

export const TableCardContent = styled(props => <CardContent {...props} />)`
  padding: 0;
  :last-child {
    padding-bottom: 0;
  }
`

export const MaterialUrl = styled.a`
  text-decoration: none;
  &:link {
    color: ${props => props.theme.palette.primary.dark};
  }
`

export const List = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media screen and (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`
