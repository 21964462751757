import { gql } from "@apollo/client"

export const categoryListQuery = gql`
  query CategoryList {
    categoryList {
      id
      name
    }
  }
`

export const courseQuery = gql`
  query Course($courseId: Int!) {
    course(id: $courseId) {
      id
      name
      slug
      description
      websiteUrl
      cost
      costCurrency
      courseorganizationSet {
        organization {
          id
          name
        }
      }
      educationMaterials {
        id
        name
        slug
        websiteUrl
        kind {
          id
          name
        }
      }
      kind {
        name
      }
    }
  }
`

export const userCourseQuery = gql`
  query UserCourse($courseId: Int) {
    userCourseRegistration(courseId: $courseId) {
      id
      code
    }
  }
`

export const resourceListQuery = gql`
  query CourseList($query: String, $categoryIds: [Int!]) {
    courseList(query: $query, categoryIds: $categoryIds) {
      id
      name
      slug
      description
      websiteUrl
      cost
      costCurrency
      courseorganizationSet {
        organization {
          id
          name
        }
      }
      educationMaterials {
        id
        name
        slug
        websiteUrl
        kind {
          id
          name
        }
      }
      kind {
        name
      }
    }
  }
`
