import React from "react"
import { ThemeProvider } from "styled-components"
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import * as S from "./style"
import { Footer } from "../../../molecules/Footer"
import { ContentAction } from "./ContentAction"
import { ContentHeader } from "./ContentHeader"
import { ContentMaterials } from "./ContentMaterials"
import { BaseToolbar } from "../../../organisms/BaseToolbar"
import { CircularProgress } from "@mui/material"

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

interface Kind {
  name: string
}

export interface Course {
  id: number
  name: string
  description: string
  websiteUrl: string
  cost: number
  costCurrency: string
  courseorganizationSet: OrganizationSet[]
  educationMaterials: EducationMaterial[]
  kind: Kind
}

interface UserRegistration {
  id: number
}

interface CourseLayoutProps {
  course: Course
  userAuthenticated: boolean
  userRegistration: UserRegistration
  loading: boolean
  onRegisterClick: () => void
  onGoToLessonplanClick: () => void
}

const CourseLayout: React.FC<CourseLayoutProps> = ({
  course,
  userAuthenticated,
  userRegistration,
  loading,
  onRegisterClick,
  onGoToLessonplanClick,
}) => {
  const intl = useIntl()

  const courseCost =
    course?.cost === 0 ? (
      <FormattedMessage defaultMessage="Free" description="option" />
    ) : (
      course?.costCurrency &&
      course?.cost &&
      Number(course.cost)?.toLocaleString(undefined, {
        style: "currency",
        currency: course?.costCurrency,
      })
    )

  return (
    <ThemeProvider theme={theme}>
      <S.Wrapper>
        <BaseToolbar
          backText={intl.formatMessage({
            defaultMessage: "Learning Resources",
            description: "back link label",
          })}
          backLink="/resources"
        />
        <S.Content>
          {loading ? (
            <S.Loading>
              <CircularProgress color="primary" />
            </S.Loading>
          ) : (
            <>
              <S.ContentActionDesktop>
                <ContentAction
                  name={course?.kind?.name}
                  userAuthenticated={userAuthenticated}
                  userRegistration={userRegistration}
                  loading={loading}
                  onRegisterClick={onRegisterClick}
                  onGoToLessonplanClick={onGoToLessonplanClick}
                />
              </S.ContentActionDesktop>
              <S.ContentMain>
                <S.ContentHeader>
                  <ContentHeader course={course} />
                </S.ContentHeader>
                <S.ContentActionMobile>
                  <ContentAction
                    name={course?.kind?.name}
                    userAuthenticated={userAuthenticated}
                    userRegistration={userRegistration}
                    loading={loading}
                    onRegisterClick={onRegisterClick}
                    onGoToLessonplanClick={onGoToLessonplanClick}
                  />
                </S.ContentActionMobile>
                {course?.educationMaterials?.length > 0 && (
                  <S.ContentMaterials>
                    <ContentMaterials
                      educationMaterials={course.educationMaterials}
                    />
                  </S.ContentMaterials>
                )}
              </S.ContentMain>
            </>
          )}
        </S.Content>
        <S.Footer>
          <Footer />
        </S.Footer>
      </S.Wrapper>
    </ThemeProvider>
  )
}

export { CourseLayout }
