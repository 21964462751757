import React from "react"
import { ThemeProvider } from "styled-components"
import { FormattedMessage, navigate } from "gatsby-plugin-react-intl"

import OpenInNewIcon from "@mui/icons-material/OpenInNew"

import theme from "../../../../gatsby-theme-material-ui-top-layout/theme"
import * as S from "./style"
import { useStyles } from "./styles.material"
import { Footer } from "../../../molecules/Footer"
import { UserTray } from "../../../molecules/UserTray"

interface Organization {
  id: number
  name: string
}

interface OrganizationSet {
  organization: Organization
}

interface EducationMaterialKind {
  id: number
  name: string
}

interface EducationMaterial {
  id: number
  name: string
  slug: string
  websiteUrl: string
  kind: EducationMaterialKind
}

export interface Course {
  id: number
  name: string
  description: string
  websiteUrl: string
  cost: number
  costCurrency: string
  courseorganizationSet: OrganizationSet[]
  educationMaterials: EducationMaterial[]
}

interface ContentHeaderProps {
  course: Course
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ course }) => {
  return (
    <>
      <S.ContentHeaderTitle>{course.name}</S.ContentHeaderTitle>
      <p>
        <FormattedMessage defaultMessage="Author" description="label" />:{" "}
        <S.BoldText>
          {course?.courseorganizationSet
            ?.map(({ organization }) => organization.name)
            .join(", ")}
        </S.BoldText>
      </p>
      <p>{course?.description}</p>
      <S.WebsiteUrl
        href={course?.websiteUrl}
        target="_blank"
        rel="nofollow noopener"
      >
        <OpenInNewIcon fontSize="small" />
        {course?.websiteUrl}
      </S.WebsiteUrl>
    </>
  )
}

export { ContentHeader }
