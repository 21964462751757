import { Category, CategoryState } from "./ResourcesPage"

export const getSelectedCategoriesFromSearchQuery = (
  categoryListData: Category[],
  searchCategory: string[]
) =>
  categoryListData?.map(({ id, name }) => ({
    id,
    name,
    selected: !!searchCategory?.find(categoryId => categoryId === String(id)),
  })) || []

export const changeSelectedCategories = (
  selectedCategories: CategoryState[],
  id: number,
  selected: boolean
) =>
  selectedCategories.map(category => ({
    id: category.id,
    name: category.name,
    selected: category.id === id ? selected : category.selected,
  })) || []
