import { Redirect, RouteComponentProps, Router, useParams } from "@reach/router"
import { navigate } from "gatsby-plugin-react-intl"
import React, { useEffect } from "react"
import { SEO } from "../components/atoms/SEO"
import { CoursePage } from "../components/sets/home/CoursePage"
import { ResourcesPage } from "../components/sets/home/ResourcesPage"
import { useIntlPathPrefix } from "../hooks/useIntlPathPrefix"

const Resources: React.FC = () => {
  const pathPrefix = useIntlPathPrefix()

  return (
    <Router basepath={`${pathPrefix}/resources`}>
      {/* 
        Redirect to support older links version.
        These links were given for people e.g. in documents submitted to futuresforumonlearning.org competition. 
        Might return webpack errors, but in production build it looks errors are not shown to the user and application works regardless of them. 
      */}
      <Redirect from="/view/:id" to={`${pathPrefix}/resources/:id`} />
      <CoursePage path="/:id" />
      <ResourcesPage path="/" />
    </Router>
  )
}

export default Resources
